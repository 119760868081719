import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/area_a_su-347.jpg","alt":null,"title":"Gabii Ark: SU 347 https://gabii.cast.uark.edu/data/browse/stratigraphic_units/347"}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://gabii.cast.uark.edu/data/browse/stratigraphic_units/347"}}>{`https://gabii.cast.uark.edu/data/browse/stratigraphic_units/347`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/area_a_su-341.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://gabii.cast.uark.edu/data/browse/stratigraphic_units/341"}}>{`https://gabii.cast.uark.edu/data/browse/stratigraphic_units/341`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/area_a_su-338.jpg","alt":null}}></MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://gabii.cast.uark.edu/data/browse/stratigraphic_units/338"}}>{`https://gabii.cast.uark.edu/data/browse/stratigraphic_units/338`}</MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-with-background","title":"Top Finds of Area A","backgroundImage":"/assets/tomb-11_assemblage.jpg","key":"top-finds-of-area-a","parentKey":"area-a"};

  